import { auth } from './firebaseConfig';
import { signInAnonymously } from "firebase/auth";
import { isDevelopment } from './firebaseConfig';

export function signIn() {
    auth.tenantId = isDevelopment ? "business-xwfn1" : "business-5hvo2";
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log(auth.tenantId)
        console.log('signed in', auth.currentUser.uid)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
        console.log(errorMessage)
      });
  }