import React from 'react'
import GraphCard from '../../components/GraphCard/GraphCard';
import SimpleDataCard from "../../components/SimpleDataCard/DataCard";
import TopThreeCard from '../../components/TopThreeCard/TopThreeCard';
import { BiRestaurant } from 'react-icons/bi'

export default function Category1Page({response}) {

  var data = response.diningProfileMetrics;


  function getMax(data){

    var maxKey = "None";
    var maxValue = 0;

    for (var key in data){
      if (data[key] > maxValue){
          maxKey = key;
          maxValue = data[key];
      }
    }
    return maxKey;
  }

  function getTopAmount(data, amount){
    var finalData = {}
    var keysSorted = Object.keys(data).sort(function(a,b){return data[b]-data[a]})

    for(var x = 0; x < amount; x++){
      finalData[keysSorted[x]] = data[keysSorted[x]];
    }

    return finalData;
  }


  var graphData =  {
    intolerances: {
      selectTitle: "Intolerances",
      graphTitle: "Top Intolerances",
      type: "pie",
      desc: "Most common intolerances for guests",
      data: data.Intolerances,      
    },
    diet: {
      selectTitle: "General Diet",
      graphTitle: "Top 5 General Diets for Guests",
      type: "pie",
      desc: "Most common general diets for guests",
      data: getTopAmount(data["General Diet"], 5),      
    },
    spice: {
      selectTitle: "Spice Level",
      graphTitle: "Spice Level",
      type: "pie",
      desc: "Most common spice levels for guests",
      data: data["Spice Level"],      
    },
    temp: {
      selectTitle: "Cooking Temperature",
      graphTitle: "Cooking Temperature",
      type: "pie",
      desc: "Most common cooking temperatures for guests",
      data: data["Cooking Temperature"],      
    },
    water: {
      selectTitle: "Water Service",
      graphTitle: "Water Service",
      type: "pie",
      desc: "Most common water services for guests",
      data: data["Water Service"],      
    },
    softBev: {
      selectTitle: "Soft Beverages",
      graphTitle: "Guest's Favorite Soft Beverages",
      type: "pie",
      desc: "Most common soft beverages for guests",
      data: data["Soft Beverages"],      
    },
    hotBev: {
      selectTitle: "Hot Beverages",
      graphTitle: "Guest's Favorite Hot Beverages",
      type: "pie",
      desc: "Most common hot beverages for guests",
      data: data["Hot Beverages"],      
    },
    alcBev: {
      selectTitle: "Alcoholic Beverages",
      graphTitle: "Guest's Favorite Alcoholic Beverages",
      type: "pie",
      desc: "Most common alocholic beverages for guests",
      data: data["Alcoholic Beverages"],      
    },
    sweetener: {
      selectTitle: "Sweetener",
      graphTitle: "Sweetener",
      type: "pie",
      desc: "Most common sweeteners for guests",
      data: data["Sweeteners"],      
    },
    creamers: {
      selectTitle: "Creamers",
      graphTitle: "Creamers",
      type: "pie",
      desc: "Most common creamers for guests",
      data: data["Creamers"],      
    },
    caffeine: {
      selectTitle: "Caffeine Level",
      graphTitle: "Caffeine Level",
      type: "pie",
      desc: "Most common caffeine level for guests",
      data: data["Caffeine Level"],      
    },
  }

  var allergiesData = {
    allergies: {
      selectTitle: "Allergies",
      graphTitle: "Guest's Allergies",
      type: "pie",
      desc: "Most common allergies for guests",
      data: data.Allergies,      
    }
  }
  return (
    <>

    <div className="flex sm:flex-row flex-col justify-between min-w-full sm:h-[350px] gap-10">

      <div className="flex sm:flex-row flex-col sm:w-1/2 justify-between gap-3">

        <div className="flex flex-col sm:w-1/2 gap-3">
          <SimpleDataCard 
            title={"Most Common General Diet"} 
            data={getMax(data["General Diet"])}
            icon={<BiRestaurant/>}  
          />
          <SimpleDataCard 
            title={"Most Common Hot Beverage"} 
            data={getMax(data["Hot Beverages"])}
            icon={<BiRestaurant/>}
          />
        </div>
        <div className='flex flex-col sm:w-1/2 gap-3'>
          <SimpleDataCard 
            background={true}
            title={"Most Common Intolerance"} 
            data={getMax(data["Intolerances"])}
            icon={<BiRestaurant/>}
          />
          <SimpleDataCard 
            title={"Most Common Cold Beverage"} 
            data={getMax(data["Cold Beverages"])}
            icon={<BiRestaurant/>}
          />
        </div>
      </div>

      <div className="sm:w-1/2">
        <GraphCard data={graphData}/>
      </div>

    </div>

    <div className="flex sm:flex-row flex-col justify-between min-w-full sm:h-[350px] gap-10">

      <div className="sm:w-1/2">
        <GraphCard data={allergiesData} noDrop={true} noDropTitle={"Allergies"}/>
      </div>

        
      <div className='flex flex-col sm:w-1/2 gap-3'>
        <TopThreeCard
          title={"Top Allergies"}
          icon={<BiRestaurant/>}
          data={getTopAmount(allergiesData.allergies.data, 3)}
          category={"Allergy"}
        />
      </div>


    </div>

    {/**Adding for space at bottom */}
    <div/>
  
    </>
  )
}
