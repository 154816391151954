import React, { useEffect, useState } from 'react'
import perfectiLogo from '../../assets/PerfectiLogo.png'

import NavbarItem from './NavbarItem'
import useWindowDimensions from '../../useWindowDimensions'

import { BsArrowLeftCircleFill } from 'react-icons/bs'
import {AiOutlineHome, AiOutlineArrowLeft, AiFillAppstore, AiOutlineMail} from 'react-icons/ai'
import BasicButton from '../Button/BasicButton'
import { useNavigate } from 'react-router'

export default function Navbar() {

    const [open, setOpen] = useState(true);
    const { width } = useWindowDimensions();
    const medWidth = 768
    const navigate = useNavigate()


    useEffect(() => {
        if(width > medWidth) setOpen(true)
        else setOpen(false)
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);


    return (
            <div
                className={` ${open ? "shadow-xl w-[200px]" : "w-20 "} duration-300 bg-white h-screen p-4 pt-8 fixed z-10`}
            >
                
                <BsArrowLeftCircleFill
                    className={`absolute cursor-pointer -right-3 top-9 w-7 h-7 border-2 rounded-full text-purple-600 bg-white md:hidden ${!open && "rotate-180"}`}
                    onClick={() => setOpen(!open)}
                />

                <div className={`${open ? "mb-8 px-4" : ""} flex flex-col items-center `}>
                    <img
                        src={perfectiLogo}
                        className={`cursor-pointer duration-500`}
                        alt="Perfecti"
                        onClick={()=>{navigate("/restaurants")}}
                    />
                    <span className={`${!open && "hidden"} text-gray-400 uppercase text-xs font-semibold ml-1`}>
                        Dashboard
                    </span>      
                </div>



                <ul className="pt-3 mx-1">

                    <span className={`${open ? "visible" : "hidden"} text-gray-400 uppercase text-xs font-semibold ml-1`}>Main Menu</span> 

                    <NavbarItem text={"Dashboard"} icon={<AiOutlineHome />} link={"/restaurants"} open={open}/>
                    <NavbarItem text={"Perfecti Apps"} icon={<AiFillAppstore />} link={"/perfecti-apps"} open={open}/>

                    
                    <div class="my-6 bg-gray-200 h-[1px]"></div> 

                    <span className={`${open ? "visible" : "hidden"} text-gray-400 uppercase text-xs font-semibold ml-1`}>Preferences</span> 
                    <NavbarItem text={"Contact Us"} icon={<AiOutlineMail/>} open={open} onClick={()=>{window.location = "mailto:info@perfecti.com"}}/>



                    <div className={`${open && "w-10/12"} absolute bottom-0 pb-10 duration-200`}>
                        <BasicButton 
                            icon={<AiOutlineArrowLeft/>} 
                            text={open && "Perfecti.com"} 
                            hidden={true}
                            onClick={() => window.open('https://perfecti.com','mywindow')}
                        />
                    </div>

                </ul>
            </div>
    );
}

