import React from 'react'
import { Link, useLocation } from 'react-router-dom'


export default function NavbarItem({ text, icon, link, open, onClick }) {

  const location = useLocation();

  return (

    <Link to={link} onClick={onClick}>

      {location.pathname.includes(link) ?
        <div className={`flex p-2.5 mt-4 mb-4 shadow-2xl rounded-md px4 cursor-pointer text-white text-sm bg-gradient-to-r from-purple-600 to-violet-400 items-center gap-x-4 duration-200`}>
          {icon}
          <span className={`${!open && "hidden"} origin-left truncate duration-200`}>
            {text}
          </span>
        </div>
        :
        <div className={`flex p-2.5 mt-3 rounded-md px4 cursor-pointer hover:bg-gradient-to-r from-purple-600 to-violet-400 hover:text-white hover:shadow-2xl text-gray-400 text-sm items-center gap-x-4 duration-200`}>
          {icon}
          <span className={`${!open && "hidden"} origin-left truncate`}>
            {text}
          </span>
        </div>
      }
    </Link>
  )
}
