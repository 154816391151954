import React, { useState, useEffect } from "react";
import { FiSearch  } from "react-icons/fi";
import { callCloudFunction, getDocument } from "../../Firebase/FirebaseRepresentable";
import NoDataModal from "../NoDataModal/NoDataModal";
import { useNavigate } from "react-router";
import BasicSpinner from "../Spinner/BasicSpinner";


export default function SearchBar() {
  const [inputValue, setInputValue] = useState("");
  const [results, setResults] = useState([]);
  const [lat, setLat] = useState()
  const [lng, setLng] = useState()
  const itemsPerPage = 5

  var [isModalOpen, setModalOpen] = useState(false)


  useEffect(()=> {
      navigator.geolocation.getCurrentPosition((position)=> {
          setLat(position.coords.latitude)
          setLng(position.coords.longitude)
      })
  })

  const fetchData = async (value) => {
    const data = {
      query: value,
      radius : 5000,
      location : {latitude : lat, longitude : lng},
      type: ["restaurant", "establishment"],
    };

    const res = await callCloudFunction("getRestaurantMenu_mobile", data);
    setResults(res);
  };

  useEffect(() => {
    if (inputValue) {
      fetchData(inputValue);
    } else {
      setResults([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const indexOfLastResult =  itemsPerPage;
  const indexOfFirstResult = indexOfLastResult - itemsPerPage;
  const currentResults = results.length > 0 && results.slice(indexOfFirstResult, indexOfLastResult);


  const navigate = useNavigate()


  return (


    <div className= "w-full relative">
          

      {
        isModalOpen && <NoDataModal isOpen={isModalOpen} closeModal={() => setModalOpen(false)}/> 
      }

      <div className="flex flex-row px-2.5 items-center duration-300 h-10 border border-slate-300 shadow-md rounded-lg bg-white">
        <span className="text-slate-500">
          <FiSearch />
        </span>
        <input
          className="search-input px-2.5 focus:outline-none w-full duration-300"
          type="input"
          placeholder= {lat && lng ? "Search For Nearby Restaurants" : "Cannnot Find Current Location..."}
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
        />
        {inputValue && results.length === 0 ? <BasicSpinner/> : ""}
      </div>
      {results.length > 0 && inputValue && (
        <ul className="results absolute z-10 mt-2 bg-white border border-slate-300 rounded-lg shadow-md">
          {currentResults.map((result) => (
            <li
              key={result.id}
              className="result-item px-2.5 py-2 hover:bg-slate-200 cursor-pointer"
              onClick={async () => {
                setInputValue("");
                setResults([]);
                const doc = await getDocument("bd_restaurants", result.id);
                console.log(doc)
                if (doc) {
                  navigate(
                    `/restaurants/profile/${result.id}`
                  );
                } else {
                  setModalOpen(true)
                }
              }}
            >
              {result.name}
              <br />
              <span className="text-sm text-gray-600">{result.address}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

