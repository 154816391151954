import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { callCloudFunction } from "../../Firebase/FirebaseRepresentable";
import Category1Page from "./DiningProfilePage";
import Category2Page from "./ServerStatsPage";
import Category3Page from "./GuestStatsPage";
import Category4Page from "./TableStatsPage";
import BasicSpinner from "../../components/Spinner/BasicSpinner";
import BasicButton from "../../components/Button/BasicButton";
import useWindowDimensions from '../../useWindowDimensions'

import { BiRestaurant, BiUser, BiTable} from 'react-icons/bi'
import { FiUsers } from "react-icons/fi";


export default function RestaurantProfile() {
  let { userID } = useParams();

  const [response, setResponse] = useState(null);
  const [category, setCategory] = useState(0);

  const loadRestaurant = async () => {
    const data = {
      restaurantId: userID,
    };
    const res = await callCloudFunction(
      "onRequestOfRestaurantData_business",
      data
    );
    setResponse(res);
  };

  useEffect(() => {
    loadRestaurant();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {width} = useWindowDimensions()

  return (

    response ?
      response.restaurantMetrics ?
        <>
          <div className="flex flex-col items-center px-10 space-y-10">
              <div className="flex lg:flex-row lg:justify-start flex-col justify-center items-center space-x-5 gap-y-8 align-center min-w-full">

                <div className="flex flex-col h-full">
                  <span className="text-xl font-semibold">{response.restaurantMetrics.info.restaurantName}</span>
                  <span className="text-sm ">{response.restaurantMetrics.info.address}</span>
                </div>
                
                <div className="flex gap-x-5">
                  <BasicButton
                    text={width >= 640 && "Dining Profile"}
                    hidden={category !== 0}
                    onClick={() => {setCategory(0)}}
                    icon={<BiRestaurant/>}
                  />
                  <BasicButton
                    text={width >= 640 && "Server Stats"}
                    hidden={category !== 1}
                    onClick={() => {setCategory(1)}}
                    icon={<BiUser/>}
                  />
                  <BasicButton
                    text={width >= 640 && "Guest Stats"}
                    hidden={category !== 2}
                    onClick={() => {setCategory(2)}}
                    icon={<FiUsers/>}
                  />
                  <BasicButton
                    text={width >= 640 && "Table Stats"}
                    hidden={category !== 3}
                    onClick={() => {setCategory(3)}}
                    icon={<BiTable/>}
                  />
                </div>  
                
              </div>
            
            {category === 0 ?
              <Category1Page response={response}/>
            : category === 1 ?
              <Category2Page response={response}/>
            : category === 2 ?
              <Category3Page response={response}/>
            : 
              <Category4Page response={response}/>
            }
          </div>
        </>
      :
        <div className="flex items-center justify-center mt-40">
          <span className="p-4 text-lg md:text-2xl font-semibold text-slate-500">Having trouble loading the data for this restaurant. Try again later.</span>
        </div>   
    :
      <div className="flex items-center justify-center mt-40">
        <BasicSpinner /> 
      </div>            
  );
}

