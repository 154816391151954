import { Route, Routes, useNavigate } from "react-router";
import React, { useEffect } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import TopBar from "./components/Topbar/TopBar";
import { signIn } from "./Firebase/firebaseAuth";
import ResturantsPage from "./pages/Resturants/ResturantsPage";
import RestaurantProfile from "./pages/RestaurantProfile/RestaurantProfile";
import PerfectiAppsPage from "./pages/PerfectiApps/PerfectiAppsPage";

function App() {
  signIn();
    
  return (
    <>

      <Navbar />
      <TopBar />

      <div className="pl-20 md:pl-[200px] pt-24 bg-gray-50 min-h-screen">
        <Routes>
          <Route path="" element={<HomeRedirect/>}/>
          <Route path="restaurants" element={<ResturantsPage />} />
          <Route path="restaurants/profile/:userID" element={<RestaurantProfile />}/>
          <Route path="perfecti-apps"element={<PerfectiAppsPage/>}/>
          <Route path="*" element={<AppRedirect/>}/>
        </Routes>
      </div>
    </>
  );
}

export default App;


function HomeRedirect(){
  const navigate = useNavigate()

  useEffect(() => {
    navigate("/restaurants")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

function AppRedirect(){
  const navigate = useNavigate()

  useEffect(() => {
    navigate("/perfecti-apps")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}