import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import Activity from './Activity';
import mockData1 from "../../assets/mockData1.png"
import mockData2 from "../../assets/mockData2.png"
import mockData3 from "../../assets/mockData3.png"
import mockData4 from "../../assets/mockData4.png"
import mockData5 from "../../assets/mockData5.png"

import { motion } from "framer-motion"


export default function RestaurantPreviewCard({title, address, id}) {

  const mockDataImgObj = {
    1: mockData1,
    2: mockData2,
    3: mockData3,
    4: mockData4,
    5: mockData5,
  }

  var [random, setRandom] = useState()

  //This is to prevent a random number from being generated on each state change
  useEffect(() => {
    setRandom(Math.floor(Math.random() * 5) + 1)
  }, [])
  

  const navigate = useNavigate()

  return (
    <motion.div 
        initial={{ scale: 0 }}
        animate={{ rotate: 0, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
        className='flex flex-col bg-white w-64 h-[300px] shadow-xl hover:shadow-2xl cursor-pointer duration-200 rounded-xl rotate-180	'
        onClick={() => {
            navigate(
              `/restaurants/profile/${id}`
            );
          }}
    >
      <img 
        className='h-2/3 rounded-t-xl object-cover'
        src={mockDataImgObj[random]}
        alt={title}
      />

    <div className='flex flex-col m-2'>
      <span className='text-gray-600 font-semibold text-lg'>{title}</span>
      <span className='text-sm'>{address} </span>
      <Activity level="medium"/>
    </div>
    </motion.div>
  )
}
