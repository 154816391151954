import React from 'react'

export default function Activity({level}) {

  level = level.toUpperCase();

  return (
    <>
    {level === "MEDIUM" ? (
      <ActivityHelper color="bg-blue-500" text="Normal Activity"/>
    ) : 
    level === "HIGH" ? (
      <ActivityHelper color="bg-green-500" text="High Activity"/>

    ) : 
    level === "LOW" ? (
      <ActivityHelper color="bg-red-500" text="Low Activity"/>
    ) : null}
  </>
  )
}

function ActivityHelper({color, text}){
  return (
    <div class={`${color} border rounded-full w-32 h-7 text-white text-sm mt-3 flex justify-center items-center`} >
      {text}
    </div>
  )
}


