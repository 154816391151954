import React from 'react'

export default function BasicButton({icon, text, hidden, onClick, disabled}) {
  return (
    <div 
      className={`${hidden ? "bg-purple-300 hover:bg-gradient-to-r from-purple-600 to-violet-400" : disabled ? "bg-purple-300 cursor-default" :"bg-gradient-to-r from-purple-600 to-violet-400"} flex p-2.5 shadow-lg hover:mb-1 hover:shadow-2xl rounded-lg cursor-pointer text-white text-sm items-center gap-x-2 duration-300`}
      onClick={!disabled && onClick}
    >
        {icon}

        {text &&
        <span className={"origin-left truncate duration-200"}>
            {text}
        </span>
        }
    </div>
  )
}
