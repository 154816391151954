import React, { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2';
import { 
    Chart as ChartJS, 
    ArcElement, 
    Tooltip, 
    Legend,   
} from 'chart.js';
import NoGraphData from '../GraphCard/NoGraphData';
import useWindowDimensions from '../../useWindowDimensions';
export default function PieChart({data}) {

    ChartJS.register(ArcElement, Tooltip, Legend);


    var [labels, setLabels] = useState(Object.keys(data))
    var [values, setValues] = useState(Object.keys(data))

    const { width } = useWindowDimensions();


    useEffect(() => {
        if(data.data != null){
            setLabels(Object.keys(data.data))
            setValues(Object.values(data.data))
        }
    }, [data])


    const formattedData = {
        labels: labels,
        datasets: [
          {
              label: 'Amount',
              data: values,
              backgroundColor: [
              'rgba(255, 99, 132, 0.8)',
              'rgba(54, 162, 235, 0.8)',
              'rgba(255, 206, 86, 0.8)',
              'rgba(75, 192, 192, 0.8)',
              'rgba(153, 102, 255, 0.8)',
              'rgba(255, 159, 64, 0.8)',
              'rgba(255, 0, 0, 0.6)',
              'rgba(151, 184, 255, 0.65)'
              ],
              borderColor: [
                'rgba(255, 99, 132)',
                'rgba(54, 162, 235)',
                'rgba(255, 206, 86',
                'rgba(75, 192, 192)',
                'rgba(153, 102, 255)',
                'rgba(255, 159, 64)',
                'rgba(255, 0, 0)',
                'rgba(151, 184, 255)'
                ],
              hoverOffset: 20,
          },
        ],
      };

  return (
    values.reduce((a, b) => a + b, 0) !== 0 ? 
        <Pie 
            data={formattedData} 
            options={
                {
                    layout: {padding: 10},
                    plugins: {
                        legend: {
                            display: width >= 768,
                            position: "left",
                            onClick: () => {}
                        },
                    },
                }
            }
        /> 
    : 
        <NoGraphData text={"There is currently no data for this category."} />
  )
}
