import React, { useEffect, useState } from 'react'
import perfectiLogo from '../../assets/PerfectiLogo.png'
import SearchBar from '../../components/Search/SearchBar'
import RestaurantPreviewCard from '../../components/RestaurantPreviewCard/RestaurantPreviewCard'
import BasicButton from '../../components/Button/BasicButton'
import {AiOutlineArrowDown, AiOutlineArrowUp} from 'react-icons/ai'
import { useQuery } from '../../Firebase/FirebaseRepresentable'
import BasicSpinner from '../../components/Spinner/BasicSpinner'
import useWindowDimensions from '../../useWindowDimensions'


export default function ResturantsPage() {

  var [seeMore, setSeeMore] = useState(false);
  const [userLat, setLat] = useState()
  const [userLong, setLng] = useState()

  const data = useQuery("bd_restaurants").sort(function(a, b){
    return calcDistanceFromUser(a.restaurantMetrics.info.location.lat, a.restaurantMetrics.info.location.lng) - calcDistanceFromUser(b.restaurantMetrics.info.location.lat, b.restaurantMetrics.info.location.lng)
  });

  var [amountOfData, setAmountOfData] = useState(0)
  const cardWidth = 300


  const { width } = useWindowDimensions();


  useEffect(()=> {
    navigator.geolocation.getCurrentPosition((position)=> {
        setLat(position.coords.latitude)
        setLng(position.coords.longitude)
    })
})

  useEffect(() => {
    if(width >= 768){
      if(seeMore === true){
        setAmountOfData(Math.floor((width-200) / cardWidth) * 2)
      }else{
        setAmountOfData(Math.floor((width-200) / cardWidth))
      }
    }else if(width <= 640){
      if(seeMore === true){
        setAmountOfData(4)
      }else{
        setAmountOfData(1)
      }
    }else{
      if(seeMore === true){
        setAmountOfData(Math.floor((width-80) / cardWidth)  * 2)
      }else{
        setAmountOfData(Math.floor((width-80) / cardWidth))
      }
    }
  }, [seeMore, width])


  function calcDistanceFromUser(lat, long){
    var R = 6371; // km
    var dLat = toRad(userLat-lat);
    var dLon = toRad(userLong-long);
    var lat1 = toRad(lat);
    var lat2 = toRad(userLat);

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c;
    return d;
    
  }

  function toRad(Value){
        return Value * Math.PI / 180;
  }


  return (
    <>
      <div class='flex flex-col place-items-center min-h-screen px-10'>
        
        <div class="flex flex-col w-full gap-y-10">

            <div class="flex flex-col items-center">
              <img
                src={perfectiLogo}
                alt="Perfecti"
                width={400}
              /> 
              <span className='text-gray-400 uppercase text-xs font-semibold ml-1'>Dashboard</span> 
            </div>

            <SearchBar />

            <span className='font-semibold text-slate-500'>Most Popular Restaurants Nearby</span>


            {!data[0] || !userLat ? 
              <span className='flex flex-col items-center justify-center w-full gap-9 text-slate-300'>
                <BasicSpinner /> 
                Getting Location...
              </span> 
            : 
              <>
                <div className='flex w-full justify-center sm:justify-between flex-wrap gap-y-7'>
                  {data.map((item, index) => (
                    index < amountOfData &&
                      <RestaurantPreviewCard 
                        title={item.restaurantMetrics.info.restaurantName}
                        id={item.restaurantMetrics.info.restaurantId}
                        address={item.restaurantMetrics.info.address}
                      />
                  ))}
                </div>

                <div className="flex justify-center w-full">
                  <BasicButton 
                    disabled={data.length <= 3}
                    text={seeMore ? "Show Less?" : "See More..."} 
                    icon={seeMore ? <AiOutlineArrowUp/> : <AiOutlineArrowDown />} 
                    onClick={seeMore ? () => {setSeeMore(false)} : () => {setSeeMore(true)}}
                  />
                </div>
              </>
            }

        </div>

            
      </div>

    </>

  )
}
