import { getFirestore, collection, getDoc, doc, getDocs, query, where, onSnapshot, FieldPath, connectFirestoreEmulator } from 'firebase/firestore';
import {getFunctions, httpsCallable,} from "firebase/functions"
import { useState, useEffect } from "react";
import {firestore} from "./firebaseConfig"
import {functions} from "./firebaseConfig"
import { BehaviorSubject } from 'rxjs';

export async function getCollection(collectionName) {
  const collectionRef = collection(firestore, collectionName);
  const snapshot = await getDocs(collectionRef);
  const docList = snapshot.docs.map(doc => doc.data());
  console.log(docList)
  return docList;
}

export async function getDocument(collectionName, documentId) {
  const docRef = doc(firestore, collectionName, documentId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
  return docSnap.data();
}

export const callCloudFunction = async (functionName, data) => {
  try {
    const callable = httpsCallable(functions, functionName);
    const response = await callable(data);
    console.log(response)
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const useQuery = (collectionName) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const q = query(collection(firestore, collectionName))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      console.log("Current data is: ", data);
      const updatedData = querySnapshot.docs.map(doc => doc.data());
      setData(updatedData);
    });
    return () => unsubscribe();
  }, [collectionName]);
  return data;
}

export function getMultiple(ids, resourceName) {
  return new Promise((resolve, reject) => {
    let results = [];
    let counter = ids.length;

    if (!counter) {
      resolve([]);
    }

    ids.forEach((id) => {
      getDocument(resourceName, id)
        .then((result) => {
          if (result) {
            results.push(result);
          }

          counter--;

          if (counter === 0) {
            resolve(results);
          }
        })
        .catch((error) => {
          counter--;

          if (counter === 0) {
            resolve(results);
          }
        });
    });
  });
}

export function observe(ids, resourceName) {
  let subject = new BehaviorSubject([]);

  let queryLimit = 10;
  let queryIds = ids.slice(0, queryLimit);

  let query = query(
    collection(firestore, resourceName),
    where(FieldPath.documentId(), "in", queryIds)
  );

  let unsubscribe = onSnapshot(query, (querySnapshot) => {
    let result = querySnapshot.docs.map((doc) => doc.data());
    subject.next(result);
  });

  let remainingIds = ids.slice(queryLimit);
  let remainingSubscription = null;

  if (remainingIds.length) {
    remainingSubscription = observe(remainingIds).subscribe((result) => {
      subject.next(subject.value.concat(result));
    });
  }

  return {
    subscribe: (callback) => subject.subscribe(callback),
    unsubscribe: () => {
      unsubscribe();
      if (remainingSubscription) {
        remainingSubscription.unsubscribe();
      }
    },
  };
}
