import React from 'react'
import SimpleDataCard from '../../components/SimpleDataCard/DataCard';
import GraphCard from '../../components/GraphCard/GraphCard';
import TopThreeCard from '../../components/TopThreeCard/TopThreeCard';
import { FiUsers } from "react-icons/fi";

export default function Category3Page({ response }) {

  function getRepeatVsNewGuests() {
    var data = response.tableMetrics.general.guestVisitsRatio;
    return ((data.repeatGuests / (data.firstTimeGuests + data.repeatGuests)) * 100).toFixed(2);
  }

  function formatKey(top, category) {

    if (category === "occasion") {
      return top;
    }

    if (category === "age") {
      switch (top) {
        case "eighteenTo24AgeRange":
          return "18-24"
        case "fiftyFiveTo64AgeRange":
          return "55-65";
        case "fortyFiveTo54AgeRange":
          return "45-54";
        case "sixtyFiveAndUpRange":
          return "65+";
        case "thirtyFiveTo44AgeRange":
          return "35-44";
        case "twentyFiveTo34AgeRange":
          return "25-34";
        case "zeroTo18AgeRange":
          return "0-18";
        default:
          return "None"
      }
    } else if (category === "gender") {
      switch (top) {
        case "totalFemale":
          return "Female";
        case "totalMale":
          return "Male";
        case "totalNonBinary":
          return "Non Binary";
        case "totalOther":
          return "Other"
        default:
          return "None";
      }
    } else if (category === "repeat") {
      switch (top) {
        case "firstTimeGuests":
          return "First Time Guests"
        default:
          return "Repeat Guests"
      }
    } else if (category === "pace") {
      switch (top) {
        case "inARush":
          return "In a Rush"
        default:
          return "Casual"
      }
    }

    //Language
    switch (top) {
      case "chineseCantoneseSpeakers":
        return "Cantonese";
      case "chineseMandarinSpeakers":
        return "Mandarin";
      default:
        top = top.replace("Speakers", "")
        return top.charAt(0).toUpperCase() + top.slice(1);
    }

  }

  function getMax(data) {

    var maxKey = "None";
    var maxValue = 0;

    for (var key in data) {
      if (data[key] > maxValue) {
        maxKey = key;
        maxValue = data[key];
      }
    }
    return maxKey;
  }


  function formatGraphData(data, selectorKey, category) {

    var formattedData = {};

    if (category === "repeat" || category === "pace") {
      for (var key in data) {
        formattedData[formatKey(key, category)] = data[key];
      }
    } else {
      for (var key2 in data) {
        //For gender
        if (key2 !== "totalPreferNotToSay")
          formattedData[formatKey(key2, category)] = data[key2][selectorKey];
      }
    }

    return formattedData;
  }


  function getTopAmount(data, amount) {
    var finalData = {}
    var keysSorted = Object.keys(data).sort(function (a, b) { return data[b] - data[a] })

    for (var x = 0; x < amount; x++) {
      finalData[keysSorted[x]] = data[keysSorted[x]];
    }

    return finalData;
  }


  var graphData = {
    gender: {
      selectTitle: "Gender",
      graphTitle: "Guest's Genders",
      type: "pie",
      desc: "Most common genders for guests.",
      data: formatGraphData(response.guestsMetrics.general.genderMetrics, "total", "gender")
    },
    language: {
      selectTitle: "Languages",
      graphTitle: "Top 8 Guest Languages",
      type: "pie",
      desc: "Most common languages for guests.",
      data: getTopAmount(formatGraphData(response.guestsMetrics.general.languagesMetrics, "total", "language"), 8),
    },
    age: {
      selectTitle: "Age Ranges",
      graphTitle: "Guest's Ages",
      type: "pie",
      desc: "Most common ages for guests.",
      data: formatGraphData(response.guestsMetrics.general.ageRanges, "total", "age"),
    },
    occasions: {
      selectTitle: "Occasions",
      graphTitle: "Occasions",
      type: "pie",
      desc: "Most common occasions for guests.",
      data: formatGraphData(response.tableMetrics.occasions, "numberOfGuests", "occasion"),
    },
    repeat: {
      selectTitle: "Repeat Guests vs New Guests",
      graphTitle: "Repeat Guests vs New Guests",
      type: "pie",
      desc: "Difference between first time and returning guests.",
      data: formatGraphData(response.tableMetrics.general.guestVisitsRatio, "", "repeat"),
    },
    pace: {
      selectTitle: "Rush vs Casual Diners",
      graphTitle: "Rush vs Casual Diners",
      type: "pie",
      desc: "Difference between guests who were in a rush and who wanted to dine casually.",
      data: formatGraphData(response.tableMetrics.paceOfService, "", "pace"),
    },
  }


  return (
    // <>

    <>
      <div className="flex flex-col sm:flex-row justify-between min-w-full sm:h-[350px] gap-10">
        <div className="flex flex-col sm:w-1/2 justify-between gap-3">

          <div className="h-1/2">
            <SimpleDataCard
              title={"Number of Active Guests"}
              icon={<FiUsers />}
              data={response.restaurantMetrics.activity.totalActiveGuests + " Guests"}
              background={true}
            />
          </div>

          <div className="flex sm:flex-row flex-col h-1/2 gap-3">
            <div className="sm:w-1/2">
              <SimpleDataCard
                title={"Total Number of Guests"}
                icon={<FiUsers />}
                data={response.restaurantMetrics.activity.totalGuests}
              />
            </div>
            <div className="sm:w-1/2">
              <SimpleDataCard
                title={"% of Repeat vs New Guests"}
                icon={<FiUsers />}
                data={getRepeatVsNewGuests() + "%"}
              />
            </div>
          </div>

        </div>

        <div className="sm:w-1/2">
          <GraphCard data={graphData} />
        </div>

      </div>


      <div className="flex flex-col sm:flex-row justify-between min-w-full sm:h-[350px] gap-10">

        <div className='sm:w-1/3'>
          <TopThreeCard
            title={"Top Occassions"}
            icon={<FiUsers />}
            category={"Occassion"}
            data={getTopAmount(graphData.occasions.data, 3)}
          />
        </div>

        <div className="flex flex-col sm:w-1/2 gap-3">
          <SimpleDataCard
            title={"Top Guest Language"}
            icon={<FiUsers />}
            data={getMax(graphData.language.data)}
          />
          <SimpleDataCard
            title={"Top Guest Gender"}
            icon={<FiUsers />}
            data={getMax(graphData.gender.data)}
          />
        </div>

        <div className='sm:w-1/3'>
          <TopThreeCard
            title={"Top Age Ranges"}
            icon={<FiUsers />}
            category={"Age Ranges"}
            data={getTopAmount(graphData.age.data, 3)}

          />
        </div>

      </div>

      <div />

    </>

  )
}
