import React from 'react';
import { 
    Chart as ChartJS, 
    Tooltip, 
    Legend,   
    CategoryScale,
    LinearScale,
    BarElement,
    Title, 
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

export default function BarChart({data}) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );

      const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
      const mockData = {
          labels,
          datasets: [
              {
                  label: 'Today',
                  data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                  backgroundColor: 'rgba(53, 162, 235, 0.7)',
                },{
                  label: 'Yesterday',
                  data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                  backgroundColor: 'rgba(53, 162, 235, 0.1)',
                },
           
          ],
      };

  return (
    <div>
        <Bar data={mockData} options={
          {
              plugins: {
                  legend: {
                      display: false,
                  },
              },
              scales: {
                  x: {
                    grid: {display: false}
                  },
                  y: {
                    grid: {display: false}
                  }
              },
          }
        }/>
    </div>
  )
}
