import React from 'react'
import phoneImage from '../../assets/PhonePreview.png'
import appStore from '../../assets/AppStore.png'
import { motion } from "framer-motion"


export default function PerfectiAppsPage() {
  return (
    <div class='flex flex-col items-center place-items-center min-h-screen px-10'>
        <div 
          class="flex lg:flex-row flex-col items-center justify-around w-full bg-white rounded-lg p-10 shadow-lg">
            
          <div className='flex flex-col gap-2 font-light text-slate-500'>
            <span className='text-3xl'>Introducing Perfecti</span>
            <span className='text-sm'>Perfecti connects Guests and Servers in <i>any</i> restaurant <i>anywhere</i>.</span>
            

            <div className='flex sm:flex-row flex-col justify-around xl:m-10 my-10 gap-4'>

              <div className='flex flex-col items-center'>
                <span className='text-yellow-600'>Perfecti</span>
                <img
                  className='cursor-pointer'
                  src={appStore}
                  alt="Download on App Store"
                  width={120}
                  onClick={() => window.open("https://testflight.apple.com/join/qwkIESem",'mywindow')}
                /> 
              </div>

              <div className='flex flex-col items-center'>
                <span className='text-yellow-600'>Perfecti Server</span>
                <img
                  className='cursor-pointer'
                  src={appStore}
                  alt="Download on App Store"
                  width={120}
                  onClick={() => window.open("https://testflight.apple.com/join/ZcdRedH5",'mywindow')}
                /> 
              </div>
              
            </div>

          </div>
            <motion.img
                initial={{ scale: 0 }}
                animate={{ rotate: 0, scale: 1 }}
                src={phoneImage}
                alt="Perfecti"
                width={400}
              /> 
        </div>      
    </div>
  )
}
