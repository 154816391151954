import React from 'react'
import { BiTable } from 'react-icons/bi';
import SimpleDataCard from '../../components/SimpleDataCard/DataCard';

export default function Category4Page({ response }) {

  var data = response.tableMetrics.general;


  function msToTime(duration) {

    if (duration === 0) {
      return "None"
    }

    var minutes = Math.floor((duration / (1000 * 60)) % 60),
      seconds = Math.floor((duration / 1000) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    if (hours === 0 && minutes === 0) {
      return seconds + " Seconds"
    }

    hours = hours < 1 ? "" : (hours === 1) ? hours + " Hour " : hours + " Hours ";


    return hours + (minutes > 0 ? minutes + " Mins" : "");
  }



  return (


    <>

      <div className="flex sm:flex-row flex-col justify-between min-w-full sm:h-[350px] gap-10">
        <div className="flex flex-col sm:w-1/2 justify-between gap-3">

          <div className="h-1/2">
            <SimpleDataCard
              title={"Number of Active Tables"}
              icon={<BiTable />}
              data={response.restaurantMetrics.activity.activeTables + " Tables"}
            />
          </div>
          <div className="h-1/2">
            <SimpleDataCard
              title={"Total Tables"}
              icon={<BiTable />}
              data={data.totalTables + " Tables"}
            />
          </div>
        </div>


        <div className="flex flex-col sm:w-1/2 justify-between gap-3">

          <div className="h-1/2">
            <SimpleDataCard
              title={"Average Tables Per Day"}
              icon={<BiTable />}
              data={data.averageTablesPerDay + " Tables"}
              background={true}
            />
          </div>

          <div className="flex sm:flex-row flex-col h-1/2 gap-3">
            <div className="sm:w-1/2">
              <SimpleDataCard
                title={"Average # of Guests Per Table"}
                icon={<BiTable />}
                data={data.averageGuestsPerTable.toFixed(2)}
              />
            </div>
            <div className="sm:w-1/2">
              <SimpleDataCard
                title={"Average Time Tables Are Open"}
                icon={<BiTable />}
                data={msToTime(data.averageTimeOpen)}
              />
            </div>
          </div>

        </div>


      </div>

      <div />

    </>
  )
}
