import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { AiOutlineDown } from 'react-icons/ai'


export default function DropDownSelect({active, setActive, data}) {

  return (
    <Menu as="div" className="text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {active}
          <AiOutlineDown className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right sm:w-52 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto">

          {data ? 
          Object.keys(data).map((item, index) => {
            return(
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={"hover:bg-gray-100 hover:text-gray-900 text-gray-700' block px-4 py-2 text-xs"}
                      onClick={() => setActive(data[item])}
                    >
                      {data[item].selectTitle}
                    </button>
                  )}
                </Menu.Item>      
              </div>
          )
        })
        :
          ""
        }
        </Menu.Items>
      </Transition>
    </Menu>
  )
}