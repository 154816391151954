import React from 'react';
import { 
    Chart as ChartJS, 
    Tooltip, 
    Legend,   
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title, 
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

export default function LineChart({data}) {


    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        Filler
      );

    

      const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July'];
      const mockData = {
        labels,
        datasets: [
            {
                label: 'Today',
                lineTension: 0.5,
                data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                borderColor: 'rgb(53, 162, 235)',
                fill: 'start',
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 400, 0);
                    gradient.addColorStop(0, "rgb(53, 162, 235, 0.3)");
                    gradient.addColorStop(1, "rgb(53, 162, 235, 0)");
                    return gradient;
                },   
                
            },
            {
                label: 'Yesterday',
                lineTension: 0.5,
                data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                borderColor: 'rgb(53, 162, 235, 0.2)',
            },
        ],
    };



  return (
    <div class="min-w-full">
        <Line 
            data={mockData} 
            options={
                {
                    elements: {
                        point: {
                            radius: 3,
                            backgroundColor: 'white',
                            borderColor: 'blue'
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    scales: {
                        x: {
                          grid: {display: false}
                        },
                        y: {
                          grid: {display: false}
                        }
                    },
                }
            }
        />
    </div>
  )
}
