import BasicButton from "../Button/BasicButton";
import {TbLayoutDashboard} from 'react-icons/tb'
import { useLocation, useNavigate } from "react-router";
import SearchBar from "../Search/SearchBar";


export default function TopBar() {

  const navigate = useNavigate()
  var location = useLocation().pathname;

  return (
    <div class="flex flex-row m-5 justify-between w-full absolute top-0 md:pl-[200px] pl-20">

    <div class="flex flex-row justify-between w-full px-5">

      <div className="md:w-9/12 w-11/12">
        {location !== '/restaurants' && <SearchBar/>} 
      </div>

      <div class="md:block hidden pr-10">
        <BasicButton text={"Download Perfecti"} icon={<TbLayoutDashboard />} onClick={()=>{navigate("perfecti-apps")}}/>
      </div>

      </div>


    </div>
  );
}
