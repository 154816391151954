import React from 'react'
import NoResultsImg from '../../assets/NoResults.png'

export default function NoGraphData({text}) {
  return (
    <div className='flex flex-col items-center justify-center sm:p-0 p-2'>
        <img
            src={NoResultsImg}
            alt="No Graph Data!"
            width={200}
        /> 
        <span className='text-slate-500 sm:text-base text-xs'>{text}</span>
    </div>
  )
}
