import React from "react";
import SimpleDataCard from "../../components/SimpleDataCard/DataCard";
import GraphCard from "../../components/GraphCard/GraphCard";
import TopThreeCard from "../../components/TopThreeCard/TopThreeCard";
import { BiUser } from 'react-icons/bi'



export default function Category2Page({ response }) {

  function msToTime(duration) {

    if (duration === 0) return "None"

    var minutes = Math.floor((duration / (1000 * 60)) % 60),
      seconds = Math.floor((duration / 1000) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    if (hours === 0 && minutes === 0) {
      return seconds + " Seconds"
    }

    hours = hours < 1 ? "" : (hours === 1) ? hours + " Hour " : hours + " Hours ";


    return hours + (minutes > 0 ? minutes + " Mins" : "");
  }

  function getFavoritePercentage() {
    var total = response.restaurantMetrics.activity.totalServers
    var fav = response.serversMetrics.serverperformance.favoritingMetrics.totalFavorites;
    return ((fav / total) * 100).toFixed(2);
  }

  function formatKey(top, category) {

    if (category === "compliments") {
      //Converts camelCase
      return top.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
    }

    if (category === "age") {
      switch (top) {
        case "eighteenTo24AgeRange":
          return "18-24"
        case "fiftyFiveTo64AgeRange":
          return "55-65";
        case "fortyFiveTo54AgeRange":
          return "45-54";
        case "sixtyFiveAndUpRange":
          return "65+";
        case "thirtyFiveTo44AgeRange":
          return "35-44";
        case "twentyFiveTo34AgeRange":
          return "25-34";
        case "zeroTo18AgeRange":
          return "0-18";
        default:
          return "None"
      }
    } else if (category === "gender") {
      switch (top) {
        case "totalFemale":
          return "Female";
        case "totalMale":
          return "Male";
        case "totalNonBinary":
          return "Non Binary";
        case "totalOther":
          return "Other"
        default:
          return "None";
      }
    }
    //Language
    switch (top) {
      case "chineseCantoneseSpeakers":
        return "Cantonese";
      case "chineseMandarinSpeakers":
        return "Mandarin";
      default:
        top = top.replace("Speakers", "")
        return top.charAt(0).toUpperCase() + top.slice(1);
    }

  }


  function formatGraphData(data, category) {

    var formattedData = {};

    for (var key2 in data) {
      //For gender
      if (key2 !== "totalPreferNotToSay")
        formattedData[formatKey(key2, category)] = data[key2]["total"];
    }

    return formattedData;
  }

  function getTopAmount(data, amount) {
    var finalData = {}
    var keysSorted = Object.keys(data).sort(function (a, b) { return data[b] - data[a] })

    for (var x = 0; x < amount; x++) {
      finalData[keysSorted[x]] = data[keysSorted[x]];
    }

    return finalData;
  }


  var graphData = {
    gender: {
      selectTitle: "Gender",
      graphTitle: "Server's Genders",
      type: "pie",
      desc: "Most common genders for servers.",
      data: formatGraphData(response.serversMetrics.serverGeneralInfo.genderMetrics, "gender")
    },
    language: {
      selectTitle: "Languages",
      graphTitle: "Top 5 Server Languages",
      type: "pie",
      desc: "Most common languages for servers.",
      data: getTopAmount(formatGraphData(response.serversMetrics.serverGeneralInfo.languagesMetrics, "language"), 5),
    },
    age: {
      selectTitle: "Age Ranges",
      graphTitle: "Server's Ages",
      type: "pie",
      desc: "Most common ages for servers.",
      data: formatGraphData(response.serversMetrics.serverGeneralInfo.ageRanges, "age"),
    },
    compliments: {
      selectTitle: "Compliments",
      graphTitle: "Server Compliments",
      type: "pie",
      desc: "Most common compliments for servers.",
      data: formatGraphData(response.serversMetrics.serverCompliments.complimentsMetrics, "compliments"),
    },
  }


  return (
    <>
      <div className="flex sm:flex-row flex-col justify-between min-w-full sm:h-[350px] gap-10">
        <div className="flex flex-col sm:w-1/2 justify-between gap-3">

          <div className="h-1/2">
            <SimpleDataCard
              title={"Total Number of Servers"}
              icon={<BiUser />}
              data={response.restaurantMetrics.activity.totalServers + " Servers"}
            />
          </div>

          <div className="flex sm:flex-row flex-col h-1/2 gap-3">

            <div className="sm:w-1/2">
              <SimpleDataCard
                title={"Number of Male Servers"}
                icon={<BiUser />}
                data={graphData.gender.data["Male"]}
              />
            </div>
            <div className="sm:w-1/2">
              <SimpleDataCard
                title={"Number of Female Servers"}
                icon={<BiUser />}
                data={graphData.gender.data["Female"]}
              />
            </div>
          </div>

        </div>

        <div className="sm:w-1/2">
          <GraphCard data={graphData} />
        </div>

      </div>


      <div className="flex sm:flex-row flex-col justify-between min-w-full sm:h-[350px] gap-10">

        <div className='sm:w-1/3'>

          <TopThreeCard
            title={"Top Age Ranges"}
            icon={<BiUser />}
            category={"Age Ranges"}
            data={getTopAmount(graphData.age.data, 3)}
          />

        </div>

        <div className="flex flex-col sm:w-1/2 gap-3">
          <SimpleDataCard
            background={true}
            title={"% of Favorited Servers"}
            icon={<BiUser />}
            data={getFavoritePercentage() + "%"}
          />
          <SimpleDataCard
            title={"Average Server Response Time"}
            icon={<BiUser />}
            data={msToTime(response.tableMetrics.general.averageResponseTime)}
          />
        </div>

        <div className='sm:w-1/3'>
          <TopThreeCard
            title={"Top Languages"}
            icon={<BiUser />}
            data={getTopAmount(graphData.language.data, 3)}
            category={"Language"}
          />
        </div>

      </div>

      <div />
    </>
  );
}
