import { initializeApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

// TODO: Firebase app configuration for web.
// Make sure to hide/secure before in production

// Switch between development and production environment
// Switch to use development before publishing live
export const isDevelopment = true;

function returnFirebaseConfig(isDevelopment) {
  return isDevelopment
    ? {
        apiKey: "AIzaSyCZ1hETOl6QOcPKRwNHWZGbYku3hsQHkV4",
        authDomain: "cocobolo-group-development.firebaseapp.com",
        databaseURL: "https://cocobolo-group-development-default-rtdb.firebaseio.com",
        projectId: "cocobolo-group-development",
        storageBucket: "cocobolo-group-development.appspot.com",
        messagingSenderId: "86349418371",
        appId: "1:86349418371:web:5d08b0490414f2c425f0b6",
        measurementId: "G-MG1BPR90DC"
      }
    : {
        apiKey: "AIzaSyAbk8qqeb1xa0BD6OjQNno8sPvb4_5I-Zo",
        authDomain: "cocobolo-group.firebaseapp.com",
        databaseURL: "https://cocobolo-group.firebaseio.com",
        projectId: "cocobolo-group",
        storageBucket: "cocobolo-group.appspot.com",
        messagingSenderId: "527649887254",
        appId: "1:527649887254:web:8a2f6e3ef5988205c49137",
        measurementId: "G-3KC16D9ZBH"
      };
}

const app = initializeApp(returnFirebaseConfig(isDevelopment), "business");

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app);


// Initialize Firebase App Check with debug mode
// prints debug token to the console
// add token to app check section in firebase console when working on localhost
// Should not need this after website is hosted on live domain
    // window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  

initializeAppCheck(app, {
  // Sets the public site key for development and production for the ReCaptchaV3Provider
  provider: new ReCaptchaV3Provider(isDevelopment ? '6LcJd_MkAAAAAHjTzIDa8T-M2ME8rxqIIhYbb7U4' : '6LcR8fkkAAAAAHhjBEABiYgmfNacuE5MWKHzsxei'),
  isTokenAutoRefreshEnabled: true
});

// Uncomment to use the Firebase emulator for Firestore and Functions 
// connectFirestoreEmulator(firestore, 'localhost', 8080);
// connectFunctionsEmulator(functions, 'localhost', 5001);
