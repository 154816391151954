import React from 'react'
import { motion } from "framer-motion"


export default function TopThreeCard({icon, title, data, category}) {

    data = Object.keys(data)



  return (
    <motion.div 
        initial={{ scale: 0 }}
        animate={{ rotate: 0, scale: 1 }}
        className = {`flex flex-col rounded-lg shadow-lg h-full bg-white duration-300 gap-10 sm:p-0 p-3`}>
        
        <span className='flex items-center gap-2 pt-7 pl-4 text-slate-400 font-medium'>
            {icon}
            {title}
        </span>
        <div className='flex flex-col h-full w-full items-center gap-3'>
            <TopThreeItem active={true} title={"Most Common " + category} data={data[0]} icon={icon}/>
            <TopThreeItem title={"Second Most Common " + category} data={data[1]} icon={icon}/>
            <TopThreeItem title={"Third Most Common " + category} data={data[2]} icon={icon}/>
        </div>

    </motion.div>
  )
}


function TopThreeItem({icon, data, title, active}){
    return (
        <div className={`${active ? "bg-gradient-to-r from-purple-400 to-purple-300 text-white" : "bg-gray-50 text-slate-500"} flex gap-3  justify-center items-center w-10/12 rounded-md p-2 shadow-md hover:shadow-2xl duration-200`}>
           
           <span className='text-xl'>{icon}</span>

            <div className='flex flex-col items-center'>
                <span className='text-xs'>{title}</span>
                <span className='text-xl font-semibold'>{data}</span>  
            </div>

        </div>
    )
}
