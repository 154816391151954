import { useState, useEffect } from 'react';

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}


function getWindowDimensions() {
  const { innerWidth: width} = window;
  return {
    width,
  };
}

export default function useWindowDimensions() {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {

    const debouncedHandleResize = debounce(function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }, 15)

    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
    }, []);

  return windowDimensions;
}