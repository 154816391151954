import { motion } from "framer-motion"

export default function SimpleDataCard ({title, data, icon, background}){
    return (
        <motion.div 
            initial={{ scale: 0 }}
            animate={{ rotate: 0, scale: 1 }}
            className = {`${background ? "bg-gradient-to-r from-orange-500 to-orange-300 text-white" : 'bg-white'} flex flex-col justify-center sm:h-full h-[100px] items-center rounded-lg shadow-lg hover:shadow-2xl duration-300`}>
            
            <span className={`${background ? "" : "text-slate-400 font-medium"} flex gap-1`}>
                {icon}
                <h4 id = "title" className = {`text-xs ease-in duration-300 pointer-events-none`}>{title}</h4>

            </span>
            
            <h2 id="title" className="text-3xl font-semibold ease-in pointer-events-none">{data}</h2>
        </motion.div>
    )
}