import { useEffect, useState } from "react";
import BarChart from "../Charts/BarChart";
import LineChart from "../Charts/LineChart";
import PieChart from "../Charts/PieChart";
import DropDownSelect from "./DropDownSelect";
import NoGraphData from "./NoGraphData";
import { motion } from "framer-motion"


export default function GraphCard({data, noDrop, noDropTitle}) {


  const [graphData, setGraphData] = useState(null)

  //Get the first graph data object
  useEffect(() => {
    if(data){
      setGraphData(data[Object.keys(data)[0]])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getGraph(){

    if(graphData == null){
      return <NoGraphData text={"There is no data yet for this restaurant."}/>
    }

    if (graphData.type === "pie"){
      return(
        <div class="min-w-full flex items-start justify-center max-h-[400px] min-w-full">
          <PieChart data={graphData}/>
        </div>
      )
    }else if(graphData.type === "line"){
      return <div class="py-10"><LineChart data={graphData}/></div>
    }
    return <div class="py-10"><BarChart data={graphData}/></div>
  }


  return (
  
      <motion.div 
        initial={{ scale: 0 }}
        animate={{ rotate: 0, scale: 1 }}
        class="flex flex-col items-center bg-white border-[1px] h-full w-full border-slate-200 rounded-lg overflow-hidden shadow-lg">
        <div className="px-4 pt-4">

          {!noDrop ? 
            <DropDownSelect 
              data={data} 
              setActive={setGraphData} 
              active={graphData ? graphData.selectTitle  : ""}
            />
          :
            <div className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 cursor-default">
              {noDropTitle}
            </div>
          }


        </div>
          <div class="px-10 w-9/12">
            {getGraph()}
        </div>

    </motion.div>


  );
}
