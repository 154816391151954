import BasicButton from '../Button/BasicButton'
import {Carousel, Modal} from 'antd'
import mockData1 from "../../assets/mockData1.png"
import mockData2 from "../../assets/mockData2.png"
import mockData3 from "../../assets/mockData3.png"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import { useNavigate } from 'react-router'
 
export default function NoDataModal({ isOpen, closeModal }) {

    const navigate = useNavigate()

    return (
        <>
        <Modal 
            title={<span className='text-slate-500 semibold'>This Restaurant is Not Using Perfecti!</span>}
            open={isOpen} 
            width={600}
            onCancel={closeModal}
            footer={false}
        >
            <div className='flex flex-col gap-5 mt-7'>

                <Carousel 
                    autoplay
                    arrows 
                    prevArrow={<AiOutlineArrowLeft />}
                    nextArrow={<AiOutlineArrowRight />}
                >
                    <img src={mockData1} alt={"Mock Data"}/>
                    <img src={mockData2} alt={"Mock Data"}/>
                    <img src={mockData3} alt={"Mock Data"}/>
                </Carousel>

                <span className='text-md text-slate-500'>
                    Start using <i className='text-purple-500'>Perfecti</i> at this restaurant in order to unlock 
                    and see data that has never been accessbile before!
                </span>

             
                <div className='flex w-full justify-end gap-4'>
                    <BasicButton text={"Download Perfecti"} onClick={() => {
                        closeModal()
                        navigate("/perfecti-apps");
                    }} />
                    <BasicButton text={"Close"} hidden={true} onClick={closeModal} />
                </div>
            </div>
        </Modal>
        </>
    )
}
